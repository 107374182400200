import Vue from "vue";
import Vuex from "vuex";
//then you use Vuex
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        drawer: false,
        loadingRoute: false,
        mobile: false,
        notification: false,
        sale: null,
    },
    mutations: {
        setUser: (state, data) => {
            state.user = data;
        },
        setDrawer: (state, data) => {
            state.drawer = data;
        },
        setLoading: (state, bool) => {
            state.loadingRoute = bool;
        },
        setMobile: (state, bool) => {
            state.mobile = bool;
        },
        setNotification:(state, data) => {
            state.notification = data;
        },
        setSale:(state, data) => {
            state.sale = data;
        },
    },
});
