import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

const routes = [];
routes.push({
    path: "*",
    component: () => import("./pages/Home.vue"),
    name: "Página Inicial",
});
routes.push({
    path: "/",
    component: () => import("./pages/Home.vue"),
    name: "Home",
});
routes.push({
    path: "/sac",
    component: () => import("./pages/Sac.vue"),
    name: "SAC",
});
routes.push({
    path: "/contribuir-com-vaquinha/:hash",
    component: () => import("./pages/Passthehat.vue"),
    name: "ContribuirVaquinha",
});
routes.push({
    path: "/contribuicao-finalizada/:id",
    component: () => import("./pages/FinishDonation.vue"),
    name: "ContribuicaoFinalizada",
});
routes.push({
    path: "/downloads",
    component: () => import("./pages/User/Downloads/Downloads.vue"),
    name: "Downloads",
});
routes.push({
    path: "/gallery",
    component: () => import("./pages/User/Gallery/Gallery.vue"),
    name: "Galeria",
});
routes.push({
    path: "/passthehats",
    component: () => import("./pages/User/Passthehats/Passthehats.vue"),
    name: "Vaquinha",
});
routes.push({
    path: "/passthehats/create",
    component: () => import("./pages/User/Passthehats/Create.vue"),
    name: "VaquinhaCreate",
});
routes.push({
    path: "/passthehats/show/:id",
    component: () => import("./pages/User/Passthehats/Show.vue"),
    name: "user.passthehats.show",
});
routes.push({
    path: "/loginforced/:token",
    component: () => import("./pages/Home.vue"),
    name: "Login como usuário",
});

routes.push({
    path: "/login",
    component: () => import("./pages/Login.vue"),
    name: "Login",
});
routes.push({
    path: "/dashboard",
    component: () => import("./pages/User/Dashboard.vue"),
    name: "Dashboard",
});
routes.push({
    path: "/profile",
    component: () => import("./pages/User/Profile.vue"),
    name: "Perfil",
});
routes.push({
    path: "/products",
    component: () => import("./pages/User/Products/List.vue"),
    name: "Produtos",
});
routes.push({
    path: "/purchases",
    component: () => import("./pages/User/Purchases/Purchases.vue"),
    name: "Minhas Compras",
});
routes.push({
    path: "/purchase/details",
    component: () => import("./pages/User/Purchases/Details.vue"),
    name: "Detalhes da Compra",
});
routes.push({
    path: "/payment",
    component: () => import("./pages/User/Payment/Payment.vue"),
    name: "Pagamento",
});
routes.push({
    path: "/recognize-basis",
    component: () => import("./pages/User/Recognize/Basis.vue"),
    name: "Reconhecimento Facial - Lista",
});
routes.push({
    path: "/recognize/:userfaceid",
    component: () => import("./pages/User/Recognize/Recognize.vue"),
    name: "Reconhecimento Facial",
});
routes.push({
    path: "/photographer",
    component: () => import("./pages/Photographer/Dashboard.vue"),
    name: "Fotógrafo - Dashboard",
});
routes.push({
    path: "/photographer/graduations",
    component: () => import("./pages/Photographer/Dashboard.vue"),
    name: "Fotógrafo - Turmas",
});

routes.push({
    path: "/admin",
    component: () => import("./pages/Admin/Dashboard.vue"),
    name: "Admin",
});
routes.push({
    path: "/admin/users",
    component: () => import("./pages/Admin/Users/List.vue"),
    name: "Admin - Usuários",
});
routes.push({
    path: "/admin/sac",
    component: () => import("./pages/Admin/Sac/List.vue"),
    name: "Admin - SAC",
});
routes.push({
    path: "/admin/graduations",
    component: () => import("./pages/Admin/Graduations/List.vue"),
    name: "Admin - Turmas",
});
routes.push({
    path: "/admin/graduations/:id/pictures/unrecognized",
    component: () => import("./pages/Admin/Graduations/UnrecognizedPictures/List.vue"),
    name: "Fotos não reconhecidas da Turma",
});
routes.push({
    path: "/admin/galleries",
    component: () => import("./pages/Admin/Galleries/List.vue"),
    name: "Admin - Galerias",
});
routes.push({
    path: "/admin/gateways",
    component: () => import("./pages/Admin/Gateways/List.vue"),
    name: "Admin - Formas de Pagamento",
});
routes.push({
    path: "/admin/financial_categories",
    component: () => import("./pages/Admin/FinancialCategories/List.vue"),
    name: "Admin - Categorias Financeiras",
});
routes.push({
    path: "/admin/coupons",
    component: () => import("./pages/Admin/Coupons/List.vue"),
    name: "Admin - Cupons de Desconto",
});
routes.push({
    path: "/admin/purchases",
    component: () => import("./pages/Admin/Purchases/List.vue"),
    name: "Admin - Compras (geral)",
});
routes.push({
    path: "/admin/payments",
    component: () => import("./pages/Admin/Payments/List.vue"),
    name: "Admin - Pagamentos (geral)",
});
routes.push({
    path: "/admin/contacts",
    component: () => import("./pages/Admin/Contacts/List.vue"),
    name: "Admin - Contatos",
});
routes.push({
    path: "/admin/instagram",
    component: () => import("./pages/Admin/Instagram/List.vue"),
    name: "Admin - Instagram",
});
routes.push({
    path: "/admin/reports",
    component: () => import("./pages/Admin/Reports/List.vue"),
    name: "Admin - Relatórios",
});
routes.push({
    path: "/admin/config",
    component: () => import("./pages/Admin/Config/List.vue"),
    name: "Admin - Configurações",
});
routes.push({
    path: "/admin/tutorials",
    component: () => import("./pages/Admin/Tutorial/List.vue"),
    name: "Admin - Tutorial",
});
routes.push({
    path: "/tutorial/*",
    component: () => import("./pages/Tutorial.vue"),
    name: "Page - Tutorial",
});
routes.push({
    path: "/admin/pushs",
    component: () => import("./pages/Admin/Push/List.vue"),
    name: "Push",
});
routes.push({
    path: "/admin/downloads-registry",
    component: () => import("./pages/Admin/DownloadRegistry/List.vue"),
    name: "Registro de Downloads",
});
routes.push({
    path: "/image/editor",
    component: () => import("./pages/User/Gallery/OnlineImageEditor.vue"),
    name: "Editor de Imagens",
});
routes.push({
    path: "/seller",
    component: () => import("./pages/Seller/Dashboard.vue"),
    name: "Painel de Vendedor",
});
routes.push({
    path: "/seller/sales",
    component: () => import("./pages/Seller/Sales/List.vue"),
    name: "Vendas",
});
routes.push({
    path: "/seller/sales/new",
    component: () => import("./pages/Seller/Sales/Form.vue"),
    name: "Nova Venda",
    beforeEnter: async (to, from, next) => {
        let user = store.state.user;
        if (!user) {
            await getUser();
            user = store.state.user;
            if (!user) {
                return next("/login");
            }
        }
        if (![2, 3].includes(user.level)) {
            return next(from.path);
        }
        return next();
    },
});
routes.push({
    path: "/seller/sales/edit/:id",
    component: () => import("./pages/Seller/Sales/Form.vue"),
    name: "Editar Venda",
    beforeEnter: async (to, from, next) => {
        let user = store.state.user;
        if (!user) {
            await getUser();
            user = store.state.user;
            if (!user) {
                return next("/login");
            }
        }
        if (![2, 3].includes(user.level)) {
            return next(from.path);
        }
        return next();
    },
});
routes.push({
    path: "/seller/photos-demo",
    component: () => import("./pages/Seller/Sales/PhotosDemo.vue"),
    name: "Demonstrativo de Fotos",
    beforeEnter: async (to, from, next) => {
        let user = store.state.user;
        if (!user) {
            await getUser();
            user = store.state.user;
            if (!user) {
                return next("/login");
            }
        }
        if (![2, 3].includes(user.level)) {
            return next(from.path);
        }
        return next();
    },
});
routes.push({
    path: "/admin/users/sellerconfig/:id",
    component: () => import("./pages/Admin/Users/SellerConfigForm.vue"),
    name: "Perfil do Vendedor",
    beforeEnter: async (to, from, next) => {
        let user = store.state.user;
        if (!user) {
            await getUser();
            user = store.state.user;
            if (!user) {
                return next("/login");
            }
        }
        if (![2].includes(user.level)) {
            return next(from.path);
        }
        return next();
    },
});

routes.push({
    path: "/purchase/:code",
    component: () => import("./pages/User/Purchases/FastPurchase.vue"),
    name: "Finalização de Compra",
    beforeEnter: async (to, from, next) => {
        return next();
    },
});

const router = new Router({
    history: true,
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

async function getUser() {
    await axios
        .get("/auth/profile")
        .then((r) => {
            store.commit("setUser", r.data);
        })
        .catch((_) => {
            localStorage.removeItem("token_app");
            store.commit("setUser", null);
        });
}

function afterEach(to) {
    setTimeout(() => {
        store.commit("setLoading", false);
    }, 500);
    Vue.nextTick(() => {
        document.title = `UOON | ${to.name}` || "UOON";
    });
}

function resetAxiosConfig() {
    axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token_app")}`,
    };
}

router.beforeEach(async (to, from, next) => {
    store.commit("setLoading", true);
    await getUser();

    //list of blocked routes
    const adminProtectedRoutes = [
        "/admin",
        "/admin/users",
        "/admin/gateways",
        "/admin/financial_categories",
        "/admin/coupons",
        "/admin/purchases",
        "/admin/payments",
        "/admin/sac",
        "/admin/contacts",
        "/admin/instagram",
        "/admin/tutorials",
    ];
    const photographerProtectedRoutes = ["/photographer"];
    const adminOrPhotographerProtectedRoutes = ["/admin/graduations"];
    const userProtectedRoutes = [
        "/dashboard",
        "/profile",
        "/gallery",
        "/products",
        "/purchases",
        "/purchase/details",
        "/payment",
        "/recognize",
        "/downloads",
    ];

    if (adminProtectedRoutes.includes(to.path)) {
        let user = store.state.user;
        if (!user) {
            return next("/login");
        }
        if (user?.level != 2) {
            if (from.path == "/") afterEach(to);
            return next("/");
        }
    }

    if (photographerProtectedRoutes.includes(to.path)) {
        let user = store.state.user;
        if (!user) {
            return next("/login");
        }
        if (user?.level != 1) {
            if (from.path == "/") afterEach(to);
            return next("/");
        }
    }

    if (adminOrPhotographerProtectedRoutes.includes(to.path)) {
        let user = store.state.user;
        if (!user) {
            return next("/login");
        }
        if (user?.level != 1 && user?.level != 2) {
            if (from.path == "/") afterEach(to);
            return next("/");
        }
    }

    if (userProtectedRoutes.includes(to.path)) {
        let user = store.state.user;
        if (!user) {
            return next("/login");
        }
        if (user?.level != 0) {
            if (from.path == "/") afterEach(to);
            return next("/");
        }
    }

    if (to.path.includes("loginforced")) {
        let token = to.path.replace("/loginforced/", "");
        localStorage.setItem("token_app", token);
        resetAxiosConfig();
        if (from.path == "/") afterEach(to);
        return next("/");
    }

    if (to.path == "/login") {
        let user = store.state.user;
        if (user) {
            if (from.path == "/") afterEach(to);
            return next("/");
        }
    }

    // otherwise allow user to access route, and check if logged and update user data if true.
    return next();
});
router.afterEach((to, from) => {
    afterEach(to);
});
export default router;
